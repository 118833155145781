@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:root {
  --primary-dark: #020a38;
  --secondary-dark: #373d5b;
  --tetiary-dark: #4e5474;
  --primary-light: #ffffff;
  --negative: #e31c28;
  --positive: #178217;
  --primary-cta: #0522ba;
}

/* :root,
[data-theme] {

} */

/**
  * This content height is dependent on the spacing
  * height of the header component which is currently
  * set to h-16.
  */
.main-content-height {
  height: calc(100vh - theme(spacing.16));
}

/* Advisor Note styles */
.an-description p {
  word-break: break-word;
}

.an-description.modal p,
.an-description.modal a {
  font-size: 18px;
}

.an-description img,
.an-description .lightbox-wrapper {
  max-width: 128px;
  float: right;
  clear: both;
}

.ai-summary li {
  list-style-position: outside;
  list-style-type: disc;
  margin-left: 16px;
}

@layer base {
  .an-description a {
    @apply font-bold text-primary-100;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    /* --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%; */
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    /* --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%; */
  }
}

.sortable-table-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sortable-table-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

table.sortable {
  /* border-collapse: separate; */
  border-spacing: 0;
}

/* tr td:last-child { border-bottom-left-radius: 10px; } */
table.sortable tr:not(.rounded-b-none),
table.sortable td:last-child:hover {
  border-bottom-right-radius: 10px;
}
table.sortable tr,
table.sortable td:last-child:hover {
  border-top-right-radius: 10px;
}

table.sortable.table {
  border: 1px solid #ddd;
}

table.sortable.table .tr:last-child .td {
  border-bottom: 0;
}

table.sortable.table .th,
table.sortable.table .td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: #fff;
  overflow: hidden;
}

table.sortable.table .th:last-child,
table.sortable.table .td:last-child {
  border-right: 0;
}

table.sortable.table.sticky {
  overflow: scroll;
}

table.sortable.table.sticky .header,
table.sortable.table.sticky .footer {
  position: sticky;
  z-index: 1;
  width: fit-content;
}

table.sortable.table.sticky .header {
  top: 0;
  box-shadow: 0px 3px 3px #ccc;
}

table.sortable.table.sticky .footer {
  bottom: 0;
  box-shadow: 0px -3px 3px #ccc;
}

table.sortable.table.sticky .body {
  position: relative;
  z-index: 0;
}

table.sortable.table.sticky [data-sticky-td] {
  position: sticky;
}

table.sortable.table.sticky [data-sticky-last-left-td] {
  box-shadow: 2px 0px 3px #ccc;
}

table.sortable.table.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
}

.h-stretch {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}

.w-stretch {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

/* font smoothing properties to match FoolIQ */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

/* Datepicker clear button */
.react-datepicker__close-icon {
  right: 35px!important;
}

.react-datepicker__close-icon:after {
  background-color: #031780!important;
}

.search-rect:hover {
  fill: #D7DCF4;
}

/* General styles for the custom nav scrollbar */
.scrollbar-nav {
  scrollbar-width: thin; /* Firefox: Thin scrollbar */
  scrollbar-color: #C2C4CF transparent;
}

/* Webkit-based browsers (Chrome, Edge, Safari) */
.scrollbar-nav::-webkit-scrollbar {
  width: 6px; /* Explicit width for WebKit browsers */
}

.scrollbar-nav::-webkit-scrollbar-thumb {
  background-color: #C2C4CF;
  border-radius: 72px;
}

.scrollbar-nav::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-nav::-webkit-scrollbar-thumb:hover {
  background-color: #a8a9b6;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
